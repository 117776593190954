import React, { useCallback, useMemo } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounce } from "lib/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { subjectByCode } from "actions/subjectDetails";
import { IState, ISubject } from "store/types";
import uniq from "lodash/uniq";
import without from "lodash/without";
import includes from "lodash/includes";
import { extractSubjectCodes } from "lib/search";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SchoolIcon from "@mui/icons-material/School";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import LinkIcon from "@mui/icons-material/Link";
import { getLabel } from "lib/parameters";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Chip } from "@mui/material";
import { featureToggles } from "config/featureToggles";

interface IProps {
  subjectsAndSets: string[];
  // shared: string[];
  onSubjectRecordIdsChanged: (ids: string[]) => any;
  errorMesage?: string | null;
  subjectsOnly?: boolean;
}

const ALL_SUBJECTS = "__SELECT_ALL_THE_SUBJECTS_IN_RETURNED_LIST__";

export const SubjectSearch = (props: IProps) => {
  const { subjectsAndSets, onSubjectRecordIdsChanged, errorMesage, subjectsOnly } = props;
  const [extraSubjectOptions, setExtraSubjectOptions] = React.useState<string[]>([]);
  const [text, setText] = React.useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const subjectDetails = useSelector((s: IState) => s.subjectDetails);
  const sharedComponents = useSelector((s: IState) => s.capsObject?.rules?.sharedComponents || []);
  const sharedComponentIds = sharedComponents.map((sc) => sc.id);

  const parentSharedComponents = useSelector((s: IState) => s.parentSharedComponents || []);
  const parentSharedComponentsIds = parentSharedComponents.map((sc) => sc.id);

  const allSharedComponents = useMemo(
    () => [...sharedComponents, ...parentSharedComponents],
    [sharedComponents, parentSharedComponents],
  );

  const dispatch = useDispatch();

  const debouncedText = useDebounce(text, 500).trim().toUpperCase();

  React.useEffect(() => {
    if (text.trim()) {
      setLoading(true);
    }
  }, [text]);

  const onOpen = useCallback(() => {
    setExtraSubjectOptions([]);
    setOpen(true);
  }, [setExtraSubjectOptions, setOpen]);
  const onClose = useCallback(() => {
    setText("");
    setOpen(false);
  }, [setText, setOpen]);
  const updateText = useCallback((e: any) => setText(e?.target?.value || ""), [setText]);

  React.useEffect(() => {
    const codes = extractSubjectCodes(debouncedText.trim());
    setExtraSubjectOptions([]);

    if (codes.length > 0) {
      const result = dispatch(subjectByCode(codes)) as any;
      result
        .then((resp: { value: ISubject[] }) => {
          const matchigRecordIds = resp.value.map((s) => s.recordId);
          setExtraSubjectOptions(matchigRecordIds.length > 1 ? [ALL_SUBJECTS, ...matchigRecordIds] : matchigRecordIds);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [debouncedText, setExtraSubjectOptions, setLoading, dispatch]);

  const onSubjectSelection = useCallback(
    (_e: any, newValue: string[]) => {
      if (includes(newValue, ALL_SUBJECTS)) {
        const withAllSubjects = uniq(without([...newValue, ...extraSubjectOptions], ALL_SUBJECTS));
        onSubjectRecordIdsChanged(withAllSubjects);
        setOpen(false);
      } else {
        onSubjectRecordIdsChanged(newValue);
        const left = extraSubjectOptions.filter((o) => !subjectsAndSets.find((i) => i === o));
        if (left.length === 1) {
          setOpen(false);
        }
      }
    },
    [onSubjectRecordIdsChanged, extraSubjectOptions, subjectsAndSets],
  );

  const getOptionLabel = useCallback(
    (option: string) => {
      if (option === ALL_SUBJECTS) {
        return "";
      }
      const subject = subjectDetails[option];
      if (subject) {
        return `${subject.name} (${subject.code})`;
      }
      const savedSet = allSharedComponents.find((sc) => sc.id === option);
      return savedSet ? getLabel(savedSet) || "" : "";
    },
    [subjectDetails, allSharedComponents],
  );

  const avatarFor = useCallback(
    (option: string) => {
      if (subjectDetails[option]) {
        return <SchoolIcon />;
      }
      return parentSharedComponentsIds.indexOf(option) >= 0 ? <LinkIcon /> : <BookmarksIcon />;
    },
    [subjectDetails, parentSharedComponentsIds],
  );
  const chipVariantFor = useCallback(
    (option: string) => (subjectDetails[option] ? undefined : "outlined"),
    [subjectDetails],
  );

  const renderOption = useCallback(
    (props: any, option: string) => {
      const label = getOptionLabel(option);
      // debugger;
      if (option === ALL_SUBJECTS) {
        return (
          <li {...props} key={props.key}>
            <DoneAllIcon /> &nbsp; Select all subjects
          </li>
        );
      }
      return (
        <li {...props} key={props.key}>
          {avatarFor(option)} &nbsp; {label}{" "}
        </li>
      );
    },
    [getOptionLabel, avatarFor],
  );

  const onDelete = useCallback(
    (option: string) => {
      const newSubjectsAndSets = [...subjectsAndSets.filter((s) => s !== option)];
      onSubjectRecordIdsChanged(newSubjectsAndSets);
    },
    [onSubjectRecordIdsChanged, subjectsAndSets],
  );

  const options = useMemo(
    () =>
      uniq(
        subjectsOnly
          ? [...subjectsAndSets, ...extraSubjectOptions]
          : [...subjectsAndSets, ...extraSubjectOptions, ...sharedComponentIds, ...parentSharedComponentsIds],
      ),
    [subjectsAndSets, extraSubjectOptions, sharedComponentIds, parentSharedComponentsIds, subjectsOnly],
  );

  const SortableItem = (props: any) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.value });

    const style = {
      transform: transform ? CSS.Transform.toString(transform) : undefined,
      transition: transition || "none",
    };

    const onMouseDown = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <Chip
          key={props.value}
          avatar={avatarFor(props.value)}
          variant={chipVariantFor(props.value)}
          label={getOptionLabel(props.value)}
          onDelete={() => onDelete(props.value)}
          onMouseDown={onMouseDown}
          style={{
            margin: "0 2px 2px 0",
            background: isDragging ? "#f0f0f0" : "white",
          }}
        />
      </div>
    );
  };

  const SortableChips = (props: any) => {
    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          delay: 200,
          tolerance: 5,
        },
      }),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    );
    const handleDragEnd = (event: any) => {
      const { active, over } = event;
      if (active && over && active.id !== over.id) {
        const oldIndex = subjectsAndSets.indexOf(active.id);
        const newIndex = subjectsAndSets.indexOf(over.id);
        const newSubjectsAndSets = arrayMove(subjectsAndSets, oldIndex, newIndex);
        onSubjectRecordIdsChanged(newSubjectsAndSets);
      }
    };
    return (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={props.values} strategy={rectSortingStrategy}>
          {props.values.map((option: any) => (
            <SortableItem key={option} value={option} />
          ))}
        </SortableContext>
      </DndContext>
    );
  };

  // TODO use Advanced filtering!
  return (
    <Autocomplete
      id="subject-code-search"
      onOpen={onOpen}
      onClose={onClose}
      open={open}
      clearOnEscape={true}
      filterSelectedOptions={true}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      options={options}
      loading={loading}
      multiple={true}
      value={subjectsAndSets}
      onChange={onSubjectSelection}
      // inputValue={text}
      onInputChange={updateText}
      disableCloseOnSelect={true}
      filterOptions={createFilterOptions({
        ignoreCase: true,
        trim: true,
        stringify: (option) => {
          if (extraSubjectOptions.indexOf(option) >= 0) {
            return text;
          }
          return getOptionLabel(option);
        },
      })}
      renderTags={(tagValue) =>
        featureToggles.deleteAndReorder ? (
          <SortableChips values={tagValue} />
        ) : (
          tagValue.map((option) => (
            <Chip
              key={option}
              avatar={avatarFor(option)}
              variant={chipVariantFor(option)}
              label={getOptionLabel(option)}
            />
          ))
        )
      }
      renderInput={(params: any) => (
        <TextField
          {...params}
          label=""
          variant="outlined"
          helperText={errorMesage || undefined}
          error={!!errorMesage}
          multiline={true}
          maxRows={20}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
